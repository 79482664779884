@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat');

//Custom theme changes (declare these before importing anything bootstrap related)
//...don't listen to stack overflow, the official docs explain it better:
//https://getbootstrap.com/docs/4.0/getting-started/theming/
$primary: rgba(22,95,89,255);
$secondary: rgba(0,155,92,255);
$success: rgba(1,156,92,255);

@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --xl-font: calc(10px + 3vmin);
  --lg-font: calc(10px + 2vmin);
  --md-font: calc(10px + 1.5vmin);
  --sm-font: calc(10px + 0.7vmin);
  --md-padding: calc(10px + 1vmin);
  --sm-padding: calc(10px + 0.33vmin);
}

.App {
  min-height: 100vh;
  text-align: start;
  display: block;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--xl-font)
}

.HomeMainPage {
  height: 85vh;
  max-width: 99%;
}

.HomeMainPage__DataNavigator__Body {
  height: 85vh;
  border: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.HomeMainPage__DataNavigator__Header {
  border: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.HomeMainPage__DataNavigator__LeftNav {
  height: 85vh;
  border-right: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.HomeMainPage__SideNav__Column {
  height: 85vh;
  border-right: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.HomeMainPage__Body {
  margin-left: 1.5vw;
  margin-right: 2.5vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.HomeMainPage__Column {
  border-left: solid;
  border-width: 1px;
  border-color: rgba(180, 68, 68, 0.1);
  height: 100vh;
}

// Customizations are made to AG grid by wrapping the Grid component in a div with the theme as the className. 
// The container height needs to be declared there for it to show up, but once that's done you can make changes to the theme itself in the sass file.
// For AG customizations to work, you need to specify the css theme "sub-class" (or whatever it's called) such as ".ag-root-wrapper", as modifications to the theme itself will not apply. 
// No idea why it works this way, but if I had to guess, it's to force finer grained control over the theme and/or encapsulate the functionality that styles each individual piece of the grid. - Mike

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 10px;
}

/* we set border radius for header row  */
.ag-theme-alpine .ag-header {
  border-radius: 10px;
}

/* we set background and border radius for odd rows */
.ag-theme-alpine .ag-row-odd {
  background-color: rgb(249, 254, 249);
  border-radius: 10px;
}

/* we set background and border radius for even rows */
.ag-theme-alpine .ag-row-even {
  background-color: white;
  border-radius: 10px;
}

.padding {
  height: 20px;
}

.auto-centered {
  width: 75vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.big-font {
  font-size: var(--xl-font);
}

.medium-font {
  font-size: var(--md-font);
}

.small-font {
  font-size: var(--sm-font);
}

.form-group {
  font-size: var(--xl-font);
}

.App-link {
  color: rgb(112, 76, 182);
}

.xlHeader{
  font-size: var(--xl-font);
}

.medium-padding {
  height: var(--md-padding);
}

.small-padding {
  height: var(--sm-padding);
}

.lgHeader {
  margin-top: var(--md-padding);
  margin-bottom: var(--sm-padding);
  font-size: var(--lg-font);
}

.mdHeader {
  font-size: var(--md-font);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}